<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Verificar o domínio por meio do registro DNS</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="!isSending">
            <div v-if="data.valid">
              <div class="alert alert-success">Domínio validado</div>
            </div>
            <p>1. Faça login no seu provedor de nome de domínio (por exemplo, registro.br, godaddy.com ou namecheap.com)</p>
            <p>2. Copie os registros abaixo na configuração do DNS referente a <strong>{{data.domain}}</strong></p>
            <div class="alert alert-secondary alert-outline line-break" role="alert" v-if="!data.automatic_security">
              <div class="icon">{{data.dns.dkim.type.toUpperCase()}}</div>
              <br/>
              <div class="text"><strong>host</strong> {{data.dns.dkim.host}} <strong>data</strong> {{data.dns.dkim.data}}</div>
              <hr>
              <div class="color-red" v-if="!data.dns.dkim.valid"><i class="fas fa-times color-red"></i> {{$t('generic-str.status.lbl-invalid')}}</div>
              <div class="color-green" v-if="data.dns.dkim.valid"><i class="fas fa-check color-green"></i> {{$t('generic-str.status.lbl-valid')}}</div>
            </div>
            <div class="alert alert-secondary alert-outline line-break" role="alert" v-else>
              <div class="icon">{{data.dns.mail_cname.type.toUpperCase()}}</div>
              <br/>
              <div class="text"><strong>host</strong> {{data.dns.mail_cname.host}} <strong>data</strong> {{data.dns.mail_cname.data | filterCNAME}}</div>
              <hr>
              <div class="color-red" v-if="!data.dns.mail_cname.valid"><i class="fas fa-times color-red"></i> {{$t('generic-str.status.lbl-invalid')}}</div>
              <div class="color-green" v-if="data.dns.mail_cname.valid"><i class="fas fa-check color-green"></i> {{$t('generic-str.status.lbl-valid')}}</div>
            </div>

            <div class="alert alert-secondary alert-outline line-break" role="alert" v-if="!data.automatic_security">
              <div class="icon">{{data.dns.mail_server.type.toUpperCase()}}</div>
              <br/>
              <div class="text"><strong>host</strong> {{data.dns.mail_server.host}} <strong>data</strong> {{data.dns.mail_server.data | filterCNAME}}</div>
              <hr>
              <div class="color-red" v-if="!data.dns.mail_server.valid"><i class="fas fa-times color-red"></i> {{$t('generic-str.status.lbl-invalid')}}</div>
              <div class="color-green" v-if="data.dns.mail_server.valid"><i class="fas fa-check color-green"></i> {{$t('generic-str.status.lbl-valid')}}</div>
            </div>
            <div class="alert alert-secondary alert-outline line-break" role="alert" v-else>
              <div class="icon">{{data.dns.dkim1.type.toUpperCase()}}</div>
              <br/>
              <div class="text"><strong>host</strong> {{data.dns.dkim1.host}} <strong>data</strong> {{data.dns.dkim1.data | filterCNAME}}</div>
              <hr>
              <div class="color-red" v-if="!data.dns.dkim1.valid"><i class="fas fa-times color-red"></i> {{$t('generic-str.status.lbl-invalid')}}</div>
              <div class="color-green" v-if="data.dns.dkim1.valid"><i class="fas fa-check color-green"></i> {{$t('generic-str.status.lbl-valid')}}</div>
            </div>

            <div class="alert alert-secondary alert-outline line-break" role="alert" v-if="!data.automatic_security">
              <div class="icon">{{data.dns.subdomain_spf.type.toUpperCase()}}</div>
              <br/>
              <div class="text"><strong>host</strong> {{data.dns.subdomain_spf.host}} <strong>data</strong> {{data.dns.subdomain_spf.data}}</div>
              <hr>
              <div class="color-red" v-if="!data.dns.subdomain_spf.valid"><i class="fas fa-times color-red"></i> {{$t('generic-str.status.lbl-invalid')}}</div>
              <div class="color-green" v-if="data.dns.subdomain_spf.valid"><i class="fas fa-check color-green"></i> {{$t('generic-str.status.lbl-valid')}}</div>
            </div>
            <div class="alert alert-secondary alert-outline line-break" role="alert" v-else>
              <div class="icon">{{data.dns.dkim2.type.toUpperCase()}}</div>
              <br/>
              <div class="text"><strong>host</strong> {{data.dns.dkim2.host}} <strong>data</strong> {{data.dns.dkim2.data | filterCNAME}}</div>
              <hr>
              <div class="color-red" v-if="!data.dns.dkim2.valid"><i class="fas fa-times color-red"></i> {{$t('generic-str.status.lbl-invalid')}}</div>
              <div class="color-green" v-if="data.dns.dkim2.valid"><i class="fas fa-check color-green"></i> {{$t('generic-str.status.lbl-valid')}}</div>
            </div>
            <p>3. Pressione <strong>verificar</strong> abaixo</p>
            <p class="text-info">
              Observação: pode levar algum tempo para que as alterações do DNS sejam aplicadas. Se não verificar imediatamente, aguarde um dia e faça uma nova verificação.
            </p>
          </div>
          <div class="text-center">
            <div v-if="isSending" class="preloader pl-lg pls-primary text-center">
              <svg class="pl-circular" viewBox="25 25 50 50">
                <circle class="plc-path" cx="50" cy="50" r="20"></circle>
              </svg>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              Verificar depois
            </button>
            <button
              v-if="!data.valid"
              :disabled="isSending"
              type="button"
              @click="verify({domain: data})"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              class="btn btn-primary"
            >
              {{$t('generic-str.verify')}}
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import MailService from '@/services/mail.service';

export default {
  components: {
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      groups: [],
      data: {
        dns: {
          dkim: {},
        },
      },
      isSending: true,
    };
  },
  mounted() {
    this.$root.$on('domain.validate', this.verify);
  },
  created() {
    this.$root.$on('domain.validate', this.verify);
  },
  methods: {
    verify(params) {
      this.isSending = true;
      MailService.validateDomain(params.domain.id).then(
        () => {
          MailService.getDomain(params.domain.id).then(
            (response) => {
              console.log(response);
              this.isSending = false;
              this.data = response;
              this.data.dns = JSON.parse(response.dns);
              if (response.valid) {
                this.$root.$emit('domains.update');
              }
            },
            (error) => {
              this.content = error;
              console.log(JSON.stringify(error.response.data));
              this.isSending = false;
            },
          );
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
      this.data = params.domain;
    },
  },
};
</script>
