<template>
  <Page :locked="false" :title="'Assine o plano de email'" :message="'Mantenha a sua lista de clientes engajados com a nossa plataforma robusta de envio de email marketing e de conteúdo.'" :features="features" :featureTitle="'O que tem no Email?'">
    <div class="content">
      <PageHeader :breadcrumbLinks="breadcrumbLinks" title="E-Mail" :subtitle="$tc('generic-str.menu.domains', 2)">
        <ul class="actions top-right">
          <li>
            <router-link to="/mail/send"
              class="btn btn-success"
              aria-expanded="false"
              style="display:none"
            >
              {{$tc('generic-str.menu.domains', 2)}}
            </router-link>
          </li>
        </ul>
      </PageHeader>
      <div class="page-content container-fluid">
        <div class="card">
          <div class="card-header" v-if="!loading">
          <!-- <div class="card-header" v-if="!loading && domains.length == 0"> -->
            <div class="input-group">
              <button
                  v-modal="{ target: 'create-domain' }"
                  class="btn btn-success emit-j-event"
                >
                  <i class="icon dripicons-plus color-white"></i
                  ><span class="hide-m">{{$t('generic-str.add')}}</span>
              </button>
            </div>
          </div>
          <div class="card-body block-el p-0">
            <div class="responsive-table">
              <div class="text-center"  v-if="domains.length == 0 && !loading">
                <i class="icon dripicons-web no-mail"></i>
                <h5>{{$t('live.none')}}</h5>
              </div>
            </div>
          </div>
        </div>
        <TableComponent :fields="fields" :items="domains" :busy="loading">
          <template #cell(valid)="slotProps">
            <span
              v-if="slotProps.data == true"
              class="badge badge-success"
            >{{$t('generic-str.status.lbl-valid')}}</span>
            <span
              v-if="slotProps.data == false"
              class="badge badge-danger"
            >{{$t('generic-str.status.lbl-non-valid')}}</span>
          </template>
          <template #cell(actions)="slotProps">
            <div class="dropdown" style="padding-right:10px;">
              <a role="button" class="dropdown-action" :to="'#menu-dropdown-'+i" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="icon dripicons-gear text-success"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right" :id="'menu-dropdown-'+i" x-placement="bottom-end" style="">
                <a class="dropdown-item" v-modal="{ target: 'domain-validate', data: slotProps.item }" @click="verify(slotProps.item)">{{$t('domain.verify')}}</a>
                <a class="dropdown-item" @click="remove(slotProps.item.id)">{{$t('generic-str.remove')}}</a>
              </div>
            </div>
          </template>
        </TableComponent>
        <br />
        <pagination :totalPages="pages" v-model="currentPage" @change="fetch" />
      </div>
      <create-domain-modal id="create-domain"></create-domain-modal>
      <domain-validate-modal id="domain-validate"></domain-validate-modal>
    </div>
  </Page>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Pagination from '@/components/PaginationComponent.vue';
import MailService from '@/services/mail.service';
import CreateDomainModal from '@/components/mails/CreateDomainModal.vue';
import DomainValidateModal from '@/components/mails/DomainValidateModal.vue';
import Swal from 'sweetalert2';
import Page from '@/components/Page.vue';
import TableComponent from '@/components/TableComponent.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
    CreateDomainModal,
    DomainValidateModal,
    TableComponent,
    Page,
  },
  data() {
    return {
      fields: [
        'domain',
        'valid',
        'actions',
      ],
      breadcrumbLinks: {
        1: '/mail/reports/stat',
        2: '/mail/domains',
      },

      domains: [],
      fetched: false,
      pages: 1,
      loading: false,
      currentPage: 1,
      features: [
        { text: 'Lista de contatos' },
        { text: 'Templates customizáveis' },
        { text: 'Relatórios em tempo real' },
        { text: 'Rastreio de cliques, aberturas e leituras de email' },
        { text: 'E muito mais...' },
      ],
    };
  },
  created() {
    this.fetch(1);
    this.$root.$on('domains.update', this.fetch);
  },
  methods: {
    locked() {
      if (this.$store.state.account.plans.email == null) {
        return true;
      }
      return false;
    },
    verify(params) {
      this.$root.$emit('domain.validate', { domain: params });
    },
    fetch(page) {
      this.loading = true;
      this.currentPage = page;
      MailService.getDomains(page).then(
        (response) => {
          console.log(response);
          this.loading = false;
          this.domains = response;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('domain.label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          MailService.deleteDomain(id).then(
            () => {
              this.fetch(1);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('domain.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    goDetail() {
      this.$router.push('detail');
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
.no-mail{
  text-align: center;
  font-size: 100px;
  margin: 0px;
  padding: 0px;
  height: 0px;
}
</style>
